<template>
  <div class="category-home">
    <div class="category-aside">
      <!-- @select="handleSelect"  -->
      <el-menu
        class="el-menu-vertical-demo"
        unique-opened
        :default-active="defaultPath"
        active-text-color="#0DAD48"
        router
      >
        <el-menu-item v-for="item in articleList" :key="item.id" :index="handerIndex(item.id)">
          <span slot="title">{{item.title}}</span>
        </el-menu-item>
      </el-menu>
    </div>
    <div class="category-main">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: "category",
  data() {
    return {
      articleList: [],
      defaultPath: ""
    };
  },
  computed: {},
  mounted() {
    this.getArticleInfoByCpath(this.$route.params.categoryId);
  },
  beforeRouteUpdate(to, from, next) {
    // 监听路由变化，如果是同个路由，修改路由参数
    if (to.name == "category") {
      this.getArticleInfoByCpath(to.params.categoryId);
    }
    next();
  },
  methods: {
    async getArticleInfoByCpath(value) {
      const { data: res } = await this.$api.getArticleInfoByCpath(value);
      this.articleList = res.data;
      if (this.$route.params.articleId) {
          this.defaultPath = `/category/${value}/article/${this.$route.params.articleId}`;
          this.$router.push(this.defaultPath);
      } else {
        if (res.data[0]) {
          // 保证默认激活选中菜单
          this.defaultPath = `/category/${value}/article/${res.data[0].id}`;
          this.$router.push(this.defaultPath);
        }
      }
    },

    handerIndex(key) {
      let path = `/category/${this.$route.params.categoryId}/article/${key}`;
      return path;
    }
  }
};
</script>
 
<style scoped>
.category-home {
  display: flex;
  justify-content: flex-start;
  height: 100%;
  width: 1500px;
}
.category-main {
  width: 1250px;
  margin-left: 250px;
}
.category-aside {
  width: 250px;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  /*  必须给top  bottom 否则无法出现滚动条 */
  top: 80px;
  bottom: 0;
}
</style>
